@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-datepicker/dist/react-datepicker.css';

@import 'react-phone-number-input/style.css';

@font-face {
  font-family: 'Proxima-Nova';
  src: url('./assets/fonts/Proxima-Nova-Font.ttf');
}

@font-face {
  font-family: 'Proxima-Nova-Thin';
  src: url('./assets/fonts/Proxima-Nova-Thin.ttf');
}

@font-face {
  font-family: 'Proxima-Nova-Bold';
  src: url('./assets/fonts/Proxima-Nova-Bold.ttf');
}
@font-face {
  font-family: 'Proxima-Nova-Extrabold';
  src: url('./assets/fonts/Proxima-Nova-Extrabold.ttf');
}
@font-face {
  font-family: 'Proxima-Nova-Black';
  src: url('./assets/fonts/Proxima-Nova-Black.ttf');
}

.zipColor path {
  stroke: #fff;
}

.centered-label {
  display: inline-flex;
  justify-content: space-evenly;
}

.full-screen__loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
  margin: auto;
  outline: 0;
  background-color: rgba(82, 82, 82, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
gap: 10px;
}

.linkNav {
  @apply p-2.5 flex rounded-md gap-6 items-center   tablet:cursor-pointer cursor-default duration-300 font-proximaNovaBold;
}

.linkNav.active {
  @apply bg-blue-100 text-blue-600;
}
.linkNav:hover {
  @apply bg-blue-900 text-blue-600;
}

.logout {
  @apply p-2.5 flex rounded-md gap-6 items-center   tablet:cursor-pointer cursor-default duration-300 font-proximaNovaBold;
}


/*toggle */

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  display: none;
}

.label {
  position: relative;
  background: hsl(217, 57%, 51%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  padding: 5px;
  margin: 0 20px;
  width: 50px;
  height: 26px;
  cursor: pointer;
}

::-webkit-scrollbar {
  display: none;
}
.otp {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}
.inputStyle {
  width: 45px !important;
  height: 45px;
  border-radius: 7px;
  border-width: 1px;
  margin-left: 8px;
  margin-right: 8px;
  background: #fbf7f7;
  font-size: 20px;
}
.name {
  font-size: 14px;
  color: hsl(234, 10%, 19%);
}

.ball {
  background: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  transition: transform 0.15s linear;
}

.checkbox:checked + .label .ball {
  transform: translateX(24px);
}

.tab-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  background: #f1f1f1;
  margin: 100px auto 0;
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.bloc-tabs {
  display: flex;
}
.tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  background: white;
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: #ef8c4d;
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}

/* Calendar Start */

.react-calendar {
  max-width: 100%;
  background: white;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  border-radius: 50%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #76baff;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #76baff;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/*Calendar End*/

#canvas-container {
  height: 60vh;
  width: auto;
  position: relative;
}

@media (min-width: 768px) {
  #canvas-container {
    height: auto;
    width: auto;
  }
}

.highlight {
  background-color: #ef8c4d;
  color: #fff;
}

.react-datepicker-wrapper {
  display: block;
}


